import React, { useState, useEffect } from 'react'


function getInitialState(settings){
    const { itemHeight, tolerance, minIndex, maxIndex, startIndex } = settings
    const amount = Math.floor(window.innerHeight / itemHeight)
    const viewportHeight = amount * itemHeight
    const totalHeight = (maxIndex - minIndex + 1) * itemHeight
    const toleranceHeight = tolerance * itemHeight
    const bufferHeight = viewportHeight + 2 * toleranceHeight
    const bufferedItems = amount + 2 * tolerance
    const itemsAbove = startIndex - tolerance - minIndex
    const topPaddingHeight = itemsAbove * itemHeight
    const bottomPaddingHeight = totalHeight - topPaddingHeight
    const initialPosition = topPaddingHeight + toleranceHeight

    return {
        settings,
        viewportHeight,
        totalHeight,
        toleranceHeight,
        bufferHeight,
        bufferedItems,
        topPaddingHeight,
        bottomPaddingHeight,
        initialPosition,
        data: []
    }
}

function getNewState(state, get, scrollTop, rowData){
    const { totalHeight, toleranceHeight, bufferedItems, settings: { itemHeight, minIndex, tolerance}} = state
    const index = minIndex + Math.floor((scrollTop - toleranceHeight) / itemHeight)
    const data = get(index, bufferedItems, state.settings, rowData)

    const topPaddingHeight = Math.max((index - minIndex) * itemHeight, 0)
    const bottomPaddingHeight = Math.max(totalHeight - topPaddingHeight - data.length * itemHeight, 0)

    var firstVisible = Math.min(index+tolerance, tolerance)
    const lastVisible = bufferedItems-tolerance
    const lastVisibleRow = data[lastVisible-1]?.row || data[data.length-1].row

    if (firstVisible < 0){ // Fix for iphone scroll up bug
        firstVisible = 0
    }
    
    const fork = {
        left: data[firstVisible].row[0].year,
        right: lastVisibleRow[lastVisibleRow.length-1].year,
    }

    return {
        ...state,
        topPaddingHeight,
        bottomPaddingHeight,
        data,
        fork
    }
}


function getData(startIndex, length, settings, rowData){
    const data = []
    const start = Math.max(settings.minIndex, startIndex)
    const end = Math.min(startIndex + length - 1, settings.maxIndex)
 
    if (start <= end) {
        for (let i = start; i <= end; i++){
            data.push({ row: rowData[i] })
        }
    }
    return data
}

function VirtualScroll({ settings, rowData, renderer, setFork }){
    const [state, setState] = useState(() => getInitialState(settings))
    useEffect(() => {
        setState(() => getInitialState(settings))
    }, [settings])

    useEffect(() => {
        const runScroller = () => setState(s => getNewState(s, getData, window.scrollY, rowData))
        window.addEventListener('scroll', runScroller, { passive: true })
        window.scroll(0, state.initialPosition)
        
        
        if(!state.initialPosition){
            runScroller()
        }
        return () => window.removeEventListener('scroll', runScroller)
        
        
    }, [state.settings, state.initialPosition, rowData]) 

    useEffect(() => {
        if(state.fork) { setFork(state.fork) }
        
    }, [state.fork, setFork])
    
    return(
        <>
            <div style={{ height: state.topPaddingHeight }}></div>
                {state.data.map(renderer)}
            <div style={{ height: state.bottomPaddingHeight}}></div>
        </>
    )
}

export default VirtualScroll