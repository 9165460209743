import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import './NavList.css'
import cvPDF from '../other/CV-ENGL 2021 TPW web[3675].pdf'

const varia = {
    init: {
        x: '100vw'
    },
    anim: {
        x: 0
    },
    exit: {
        x: '100vw'
    },
    trans: {
        type: 'tween'
    }
}

/// Fetch All Gallery Links in index.js and set state
/// On Link Click Fetch Pictures
/// Fetch Image 

/// Nav List generate NavListFrom Links stored in state

function NavList( {setNav} ){

    return (
        <motion.div
        variants={setNav ? varia : {}}
        initial='init'
        animate='anim'
        exit='exit'
        transition='trans'
        className='NavList'
        >
            <div className="upper-container">
                <Link to='/' className="naw-backlink" onClick={() => setNav(false)}>
                    <svg width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="18.1143" height="2.86367" transform="matrix(-0.719871 -0.694108 0.719871 -0.694108 13.9385 27)" fill="#C4C4C4"/>
                        <rect width="19.6244" height="2.60179" transform="matrix(-0.719871 0.694108 -0.719871 -0.694108 16 1.80591)" fill="#C4C4C4"/>
                    </svg>


                    <h3 className="naw-home" >Home</h3>

                </Link>
            </div>
            <div className="link-container">
                <Link to='/gallery' className="naw-link" onClick={() => setNav(false)}>
                    <h4 className="naw-pictureGallery">Picture Gallery</h4>
                    <svg width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="18.1143" height="2.86367" transform="matrix(0.719871 0.694108 -0.719871 0.694108 2.06152 0)" fill="#C4C4C4"/>
                        <rect width="19.6244" height="2.60179" transform="matrix(0.719871 -0.694108 0.719871 0.694108 0 25.1941)" fill="#C4C4C4"/>
                    </svg>

                </Link>
                <a href={cvPDF} className="naw-link" onClick={() => setNav(false)}>
                    <h4 className="naw-cv">CV</h4>
                    <svg width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="18.1143" height="2.86367" transform="matrix(0.719871 0.694108 -0.719871 0.694108 2.06152 0)" fill="#C4C4C4"/>
                        <rect width="19.6244" height="2.60179" transform="matrix(0.719871 -0.694108 0.719871 0.694108 0 25.1941)" fill="#C4C4C4"/>
                    </svg>

                </a>
            </div>
        </motion.div>
    )
}

export default NavList