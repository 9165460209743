import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'


import './HomeText.css'
import ParagraphScroll from './ParagraphScroll'

import cvPDF from '../other/CV-ENGL 2021 TPW web[3675].pdf'

const pDict = {
    0: <>I focus on painting as a vitalistic, transformative agency. I witness certain moments of painting practice, when it seems that the painting itself takes the lead and guides the process. In those moments, I feel like gaining extra energy, I become vitalized.</>,
    1: <>I work with synaesthetic states of bodymind, amodal sensations and <em>vitality forms</em>; the events of sensuality that flee the attempts at conscious conceptualisation.</>,
    2: <>As an art teacher, I visit an art student´s studio to discuss their (often unfinished) work. Besides the student and myself, the third and the most essential party in this discussion is the painting. The coming together of these three parties can create an extraordinary inter/intra-subjective encounter and connection that is unlikely to happen in any other occasion.</>,
    3: <> Painting can be regarded as an <em>intra-active</em> meeting point, a node of multisensory gathering that is able to create a special kind of vitalistic change.</>,

}

const pArray = [pDict[0], pDict[1], pDict[2], pDict[3]]

function HomeText( { isMobile, scrollYProgress }){

  


 
    return(
        <motion.div className='HomeText'>
            <h1 className="Name">
                Tarja Pitkänen-Walter
            </h1>
           
            { isMobile ? null : 
                <><Link className="Link" to="/gallery"><motion.p>Go to Picture Gallery</motion.p></Link>
                <a className="Link" href={cvPDF} target = "_blank" rel="noreferrer">CV</a> </>
            }
            
            <ParagraphScroll pArray={pArray} scrollMotion={scrollYProgress} isMobile={isMobile}></ParagraphScroll>
        
        </motion.div>
    )
}

export default HomeText