import { useState, useEffect} from 'react'

export default function useWallSize(wall){
    const [width, setWidth] = useState(undefined)
    const [wallDimensions, setWallDimensions] = useState({
        columns: undefined,
        elementWidth: undefined,
    })
    
    useEffect(() => {
        function handleResize(){
            if (wall.current){
                setWidth([wall.current.clientWidth])
            }
            
        }
        handleResize()
        let listener = window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', listener)

    }, [wall])

    useEffect(() => {
        const minSize = window.innerWidth > 800 ? 300 : 200 
        const columns = Math.max(Math.floor(width / minSize), 1)
        const elementWidth = Math.floor(width/columns)
        console.log(elementWidth, wallDimensions.elementWidth)
        if (elementWidth !== wallDimensions.elementWidth){
            setWallDimensions({ columns: columns, elementWidth: elementWidth, elementHeight: elementWidth})
        }
    
    }, [width, wallDimensions.elementWidth])

    return wallDimensions
}