import { useState } from 'react'


function CoverImage( { img, index }){
    const [isLoaded, setIsLoaded] = useState(false)
    return (
        <img src={img} onLoad={() => setIsLoaded(true)} style={{opacity: isLoaded ? 1 : 0}} className={`kuva kuva${index}`} alt="Cover"></img>
    )
}

export default CoverImage