import React, { useRef, useState, useEffect }from 'react'
import { Route, Switch, useLocation, Link } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'

import Home from './Home'
import NavList from './components/NavList'
import Gallery from './Gallery'

function App() {
  const location = useLocation()
  const burgerRef = useRef(null)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800)
  const [inNav, setInNav] = useState()
  
  useEffect(() => {
    window.onresize = () => setIsMobile(window.innerWidth < 800)
  }, [])


  return (
    <div className="App">
      {isMobile ?
        <motion.div
          animate={{rotate: inNav ? 90 : 0}}
          transition={{type: "tween"}}
          ref={burgerRef}
          className="burger"
          onClick={() =>{
            setInNav(!inNav)
          }}>
          <svg width="33" height="11" viewBox="0 0 33 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="33" height="3" fill="#C4C4C4"/>
            <rect y="8" width="33" height="3" fill="#C4C4C4"/>
          </svg>
        </motion.div>
        : null 
      }

      
      <AnimatePresence>{isMobile && inNav ? <NavList setNav={setInNav}></NavList> : null}</AnimatePresence>
      

        <Switch location={location} key={location.pathname.length === 1 ? 1 : 0}>
          
          <Route path="/" exact>
        
            <Home isMobile={isMobile}></Home>
  
          </Route>

          <Route path='/gallery'>
              
                {isMobile ? null : <Link to='/' className="backlink">
                  <svg style={{pointerEvents: 'none'}} className="link__" width="48" height="45" viewBox="0 0 64 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="4" y="28" width="60" height="6" fill="#C4C4C4"/>
                      <rect y="32.0098" width="41.2173" height="5" transform="rotate(-50.9511 0 32.0098)" fill="#C4C4C4"/>
                      <rect x="3.32007" y="28" width="41.2173" height="5" transform="rotate(41.6085 3.32007 28)" fill="#C4C4C4"/>
                  </svg>
                </Link>}
                <Gallery></Gallery>
      
              
          </Route>
        

        </Switch>
      
    </div>

  );
}

export default App;
