import React, { useState, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import './ImageFrame.css'



function ImageFrame({ img_data, setInPreview, load, cancel } ){
    const src = "Small/"+img_data.path
    const divRef = useRef(null)
    const [isReady, setIsReady] = useState(false)
    const [source, setSource] = useState("")

    

    useEffect(() => {
        const loadTrigger = () => {
            setSource(src)
        }
        load(src, loadTrigger)

        return () => cancel(src)
    }, [cancel, load, src])

    return(
 
        <motion.div
            ref={divRef}
            className='ImageFrame'
            whileHover={{ scale: 1.01 }}
            onClick={() => setInPreview(true)}
            >
                {isReady ? null : 
                    <svg className='loading_svg' svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" version="1.0" width="64px" height="64px" viewBox="0 0 128 128">
                        <g><path d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z" fill="#000000"/><animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1400ms" repeatCount="indefinite"/></g>
                    </svg>
                }
                
                <img
                    className='image'
                    //loading='lazy'
                    style={isReady ? null : {opacity: 0, width: 0}}
                    onLoad={() => setIsReady(true)}
                    alt=""
                    src={source} 
                />
                

                
    
            
        </motion.div>
    )
}


export default ImageFrame