import { useRef } from 'react'



export default function useLoadManager(){
    const max = 6
    
    const listenersRef = useRef({})
    const loadedRef = useRef([])
    const procRef = useRef([])
    const queueingRef = useRef([])

    function triggerLoad(src){
        if (src in listenersRef.current) {
            listenersRef.current[src]()
            delete listenersRef.current[src]
        }
    }
    function addToQ(src){
        queueingRef.current.push(src)
        
        if (procRef.current.length < max){
            moveToP(src)
        }
    }
    function pullFromG(){
        const head = queueingRef.current[0]
        if (head){
            moveToP(head)
        }
    }
    function moveToP(src){
        queueingRef.current = queueingRef.current.filter(c => c !== src) // Remove from Q

        procRef.current.push(src) // Add to P

        const img = new Image()
        img.onload = () => {    // On loaded actions: moveToL, getReplacementFromQ triggerCallback, removeFromCallbacks
            moveToL(src)
            pullFromG()
            triggerLoad(src)
            
        }
        img.src = src
    }
    function moveToL(src){
        procRef.current = procRef.current.filter(c => c !== src)
        loadedRef.current.push(src)
    }

    function load(src, func){
        if (loadedRef.current.includes(src)) {
            func()
        } else {
            if (!(queueingRef.current.includes(src) || procRef.current.includes(src))) {
                addToQ(src)
            }

            listenersRef.current[src] = func
        }
    }
        
    function cancel(src){
        delete listenersRef.current[src]
        
        if (!(loadedRef.current.includes(src) || procRef.current.includes(src))) {
            queueingRef.current = queueingRef.current.filter(c => c !== src)
        }
    }

    return [load, cancel]
}