import { useState, useEffect } from 'react'

export default function useRowFormat(data, columns){
    const [rowArray, setRowArray] = useState(undefined)
    
    useEffect(() => {
        if (columns && data){
            var tempRowArray = []
            for (let i = 0; i<data.length; i += columns){
                tempRowArray.push(data.slice(i, i+columns))
            }
            setRowArray(tempRowArray)
        }
        

    }, [columns, data])
    
    
    return rowArray
}