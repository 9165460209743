import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import './Timeline.css'
const years = [2022, 2015, 2010, 2005, 2000, 1995, 1990, 1985, 1980]

function dateToPercentage(date){
    const length = years[0]-years[years.length-1]
    const dateLength = years[0]-date
    return dateLength/length*100
}

function Timeline({ fork, setYear }){
    console.log("Timeline Rerender")
    //// Setup Fork To display
    
    return (
        <div className='Timeline'>
            <div className='meter'>
                <motion.div 
                initial={{top: 0, height: 0}} 
                animate={{top: `${dateToPercentage(fork.left)}%`, height: `${dateToPercentage(fork.right)-dateToPercentage(fork.left)+2}%`}}

                className='TimeFork'></motion.div>
            </div>
            <div className='years'>
                {years.map((v, i) => <motion.div 
                        key={i}
                        className='year_div'
                        initial={{color: 'rgb(150, 150, 150)'}}
                        whileHover= {{scale: 1.3, color: 'rgb(0, 0, 0)'}}
                        onClick={function(){setYear(v)}}
                    
                    >
                        <Link className='year' to={"/gallery"}>{v}</Link>
                    </motion.div>)}
            </div>
        </div>
    )
}


export default Timeline