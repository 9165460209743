import {useState, useEffect} from 'react'

import './Preview.css'

function Preview({ index, data, setInPreview }){
    const [isLoaded, setIsLoaded] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(index)
    console.log("Current data:", data[currentIndex].path)
    useEffect(() => {
        setIsLoaded(false)
    }, [currentIndex])

    return(
        <div className="Preview">
            
            <svg onClick={() => setInPreview(false)} className="Cross" width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1.41406" y="40.4263" width="58" height="4" rx="2" transform="rotate(-45 1.41406 40.4263)" fill="white"/>
                <rect x="42.4263" y="43.2546" width="58" height="4" rx="2" transform="rotate(-135 42.4263 43.2546)" fill="white"/>
            </svg>
            {(currentIndex !== 0) ?
                <svg onClick={() => setCurrentIndex(currentIndex - 1)} className="Arrow LeftArrow" width="42" height="72" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="11.6133" y="23.3308" width="15.3649" height="2.42901" transform="rotate(-135 11.6133 23.3308)" fill="white"/>
                    <rect x="13.3309" y="1.56055" width="16.6458" height="2.20688" transform="rotate(135 13.3309 1.56055)" fill="white"/>
                </svg>
            : null
            }
            
            {(currentIndex !== data.length-1) ?
                <svg onClick={() => setCurrentIndex(currentIndex + 1 )} className="Arrow RightArrow" width="42" height="72" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1.71759" width="15.3649" height="2.42901" transform="rotate(45 1.71759 0)" fill="white"/>
                    <rect y="21.7703" width="16.6458" height="2.20688" transform="rotate(-45 0 21.7703)" fill="white"/>
                </svg>
            : null
            }
        
            <div className="PreviewFrame">
                
                { isLoaded ? null : 
                    <svg className='loading_svg' svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" version="1.0" width="64px" height="64px" viewBox="0 0 128 128">
                        <g><path d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z" fill="#FFFFFF"/><animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1400ms" repeatCount="indefinite"/></g>
                    </svg> }
                <img className="previewImage" onLoad={() => setIsLoaded(true)} style={{opacity: isLoaded ? 1 : 0, width: isLoaded ? "inherit" : 0 }} src={"Large/"+data[currentIndex].path} alt="Preview"/>
                
                
            </div>
            

        </div>
    )

}

export default Preview