import { motion, useTransform } from 'framer-motion'
import { useState, useRef, useEffect } from 'react'

function ParagraphScroll( { pArray, padding, scrollMotion, isMobile } ){
    const [scrollHeight, setScrollHeight] = useState(0)
    const [ref0, ref1, ref2, ref3] = [useRef(null), useRef(null), useRef(null), useRef(null)]
    const pRefArray = [ref0, ref1, ref2, ref3]
    const opacity1 = useTransform(scrollMotion, [0, 0.25, 0.5, 1], [1, 0, 0, 0])
    const opacity2 = useTransform(scrollMotion, [0, 0.25, 0.5, 1], [0.5, 1, 0, 0])
    const opacity3 = useTransform(scrollMotion, [0, 0.25, 0.5, 0.75], [0, 0.5, 1, 0])
    const opacity4 = useTransform(scrollMotion, [0, 0.25, 0.75, 1], [0, 0, 0.8, 1])
    const opacityArray = [opacity1, opacity2, opacity3, opacity4]
    const y = useTransform(scrollMotion, isMobile ? [0, 1] : [0, 0.2 , 1], isMobile ? [100, scrollHeight] : [0, 0, scrollHeight*1.2])

    
    useEffect(() => {
        function CalculateNSetScrollHeight(){
            const requiredHeight = ref0.current.offsetHeight+ref1.current.offsetHeight+ref3.current.offsetHeight
            setScrollHeight(-requiredHeight)
            
        }
        CalculateNSetScrollHeight()
        window.addEventListener("resize", CalculateNSetScrollHeight())

        return () => {
            window.removeEventListener("resize", CalculateNSetScrollHeight)
        }

    }, [ref0, ref1, ref3])
    return (
        <motion.div style={{y}} className="ParagraphScroll">
            <motion.div style={{}}>
                {pArray.map((p, i) => {
                    return <motion.div style={{opacity: opacityArray[i]}} key={p+i} ref={pRefArray[i]} className="para">{p}</motion.div> 
                })}
            </motion.div>
        </motion.div>
    )

}


export default ParagraphScroll