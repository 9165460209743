import React, { useState } from 'react'
import { useViewportScroll } from 'framer-motion'

import HomeText from './components/HomeText'
import CoverImage from './components/CoverImage'
import MobileHome from './MobileHome'

import './Home.css'
import keinu from './img/keinu.png'
import hampaat from './img/HampaatYläAla.png'
import teline from './img/Teline.jpg'

const picArray = [keinu, hampaat, teline]



function Home( { isMobile }){
    const [position, setPosition] = useState(0)
    const { scrollYProgress } = useViewportScroll()

    scrollYProgress.onChange((x) => {
        const index = Math.floor(x*(picArray.length))
     
        if (position !== index){
            setPosition(index)
        }
        
    })

    return(
        <div className='Home'>
            {!isMobile ? <><HomeText isMobile={isMobile} position={position} scrollYProgress={scrollYProgress}></HomeText>
                <div className="ImageScroll">{picArray.map((v, i) => <CoverImage index={i} key={'i' + i} img={v}></CoverImage>)} </div></>
                : <MobileHome></MobileHome>}

        </div>
    )
}

export default Home