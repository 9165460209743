import React, { useState, useEffect, useRef } from 'react'
import Timeline from './components/Timeline'
import VirtualScroll from './components/VirtualScroll'
import './Gallery.css'
import useRowFormat from './hooks/useRowFormat'
import useWallSize from './hooks/useWallSize'
import useLoadManager from './hooks/useLoadManager'
import ImageFrame from './components/ImageFrame'
import Preview from './components/Preview'

import dataArray from './other/imageArray.json'



function Gallery(){
    const wallRef = useRef(null) 
    const wallDimensions = useWallSize(wallRef)
    const rowData = useRowFormat(dataArray, wallDimensions.columns)
    const [load, cancel] = useLoadManager()
    const [SETTINGS, setSETTINGS] = useState(undefined)
    const [fork, setFork] = useState({
        left: 0,
        right: 0,
    })
    const [inPreview, setInPreview] = useState(false)

    useEffect(() => {
        if(rowData){
            setSETTINGS((prev) => {
                return {      
                    itemHeight: wallDimensions.elementHeight,
                    tolerance: 3,
                    minIndex: 0,
                    maxIndex: rowData.length-1,
                    startIndex: 0 
                }
            })
        }   
    }, [rowData, wallDimensions.elementHeight])


    const launchPreview = (index) => {
        setInPreview({index})
    } 
   
    const rowRender = (rowData) => {
        return (
            <div style={{height: SETTINGS.itemHeight}} key={'R' + rowData.row[0].path} className='row'>
                {rowData.row?.map(v => <ImageFrame load={load} cancel={cancel} setInPreview={() => launchPreview(v.index)} key={v.path} img_data={v}></ImageFrame>)}
            </div>
        )
    }

    const setYear = (year) => {
        var index = rowData.findIndex(c => c.map(v => v.year).includes(""+year))
        if (index === -1){
            index = rowData.map(v => Math.max(...v.map(vv => vv.year))).findIndex(c => c < year)-1
        } if (index === -2){
            index = rowData.length-1
        }
        
        setSETTINGS(prev => {return {
            ...prev,
            startIndex: index 
        }})
    }

    return(
        <div className='Gallery'>
            {inPreview ? <div className="PreviewLayer">
                <Preview data={dataArray} index={ inPreview.index } setInPreview={setInPreview}></Preview>
            </div> : null}
            <Timeline fork={fork} setYear={setYear}></Timeline>
            <div className='Wall' ref={wallRef}>
                {SETTINGS ? <VirtualScroll 
                    settings={SETTINGS}
                    rowData={rowData}
                    renderer={rowRender}
                    setFork={setFork}>   
                </VirtualScroll> : null
               }
            </div>

            <div className="Credits">
                <h4 className="CreditsHeader">Photos:</h4>
                <ul className="CreditList">
                    <li>Jussi Tiainen</li>
                    <li>Yehia Eweis</li>
                    <li>Petri Virtanen / Kansallisgalleria</li>
                    <li>Tommi Keränen / KuvA</li>
                    <li>Harri Larjosto</li>
                    <li>Mika Friman / Oulun taidemuseo</li>
                    <li>Esko Toivari / KuvA</li>
                    <li>Sakari Viika</li>
                    <li>Tarja Pitkänen-Walter</li>
                    <li>Oliver Walter</li>
                </ul>
            </div>
                
 
        </div>
    )
}

export default Gallery