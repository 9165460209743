import React from 'react'


import CoverImage from './components/CoverImage'

import './MobileHome.css'
import keinu from './img/keinu.png'
import hampaat from './img/HampaatYläAla.png'
import teline from './img/Teline.jpg'
import taulu from './img/taulu.jpeg'


function MobileHome(){
    

    return(
        <>
        <h1 className="Name">
                Tarja Pitkänen-Walter
        </h1>
        <div className='MobileHome'>
            
            <CoverImage index={1} img={keinu}></CoverImage>
            <p className="MobileParagraph">I focus on painting as a vitalistic, transformative agency. I witness certain moments of painting practice, when it seems that the painting itself takes the lead and guides the process. In those moments, I feel like gaining extra energy, I become vitalized.</p>
            <CoverImage index={2} img={hampaat}></CoverImage>
            <p className="MobileParagraph">I work with synaesthetic states of bodymind, amodal sensations and <em>vitality forms</em>; the events of sensuality that flee the attempts at conscious conceptualisation.</p>
            <CoverImage index={3} img={taulu}></CoverImage>
            <p className="MobileParagraph">As an art teacher, I visit an art student´s studio to discuss their (often unfinished) work. Besides the student and myself, the third and the most essential party in this discussion is the painting. The coming together of these three parties can create an extraordinary inter/intra-subjective encounter and connection that is unlikely to happen in any other occasion.</p>
            <CoverImage index={4} img={teline}></CoverImage>
            <p className="MobileParagraph"> Painting can be regarded as an <em>intra-active</em> meeting point, a node of multisensory gathering that is able to create a special kind of vitalistic change.</p>

        </div>
 
        </>
    )
}

export default MobileHome